
import React from 'react'
import styled from 'styled-components'
import { PageTitle, PageTitle2, PageTitle3, PageParagraph, PageRegular, PageLabel, PageSmall } from '../../components/PageTitle';
import { Box, Large, colors } from 'js-components';
import TestimonialCard from './TestimonialCard';

import FiveStars from './5stars.svg';

import BillyB from './billyB.png';
import TaylorS from './taylorS.png';
import JackieF from './jackieF.png';
import RachelM from './rachelM.png';
import TimothyR from './timothyR.png';
import MichaelM from './michaelM.png';

function TestimonialGrid(props) {
    const { children } = props
  
    return (
      <CustomerCardContainer>
      {children}
      </CustomerCardContainer>
    )
}

export default TestimonialGrid

const AvatarImg = styled.img`
  width: 60pt;
  height: 60pt;
  border-radius: 60pt;
  border: ${colors.trulyBlue} 4px solid;
  margin: 8pt;
  margin-right: 8pt;
`

const Icon = styled.img`
  width: 60pt;
  height: 60pt;
  padding: 8pt;
  margin-right: 8pt;
`

TestimonialGrid.defaultProps = {
    rows: 2
}

const TextWrapper = styled.div`
  width: 100%;
`

const CustomerCardContainer = styled.div`
display: flex;
max-width: 1280px;
flex-direction: row;
flex-wrap: wrap;
align-content: center;
align-items: stretch;
justify-content: center;
width: 100%;
@media (max-width: 1000px) {
  flex-direction: column;
}
`;

const CustomerCard = styled.div`
  background-color: white;
  width: 32%;
  border-radius: 8pt;
  margin: 8px;
  height: auto;
  flex-grow: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 16pt 8pt;
  text-align: left;
  justify-content: left;
  align-items: top;
  color: black;
  @media (max-width: 1000px) {
    width: 100%;
  }
`