import React from 'react'
import styled from 'styled-components'
import { PageTitle, PageTitle2, PageTitle3, PageParagraph, PageRegular, PageLabel, PageSmall } from '../PageTitle';
import { Box, Large, colors } from 'js-components';

import FiveStars from './5stars.svg';

import AvatarBlue from './avatarBlue.png';
import BillyB from './billyB.png';
import TaylorS from './taylorS.png';
import JackieF from './jackieF.png';
import RachelM from './rachelM.png';
import TimothyR from './timothyR.png';
import MichaelM from './michaelM.png';
import JackieL from './jackieL.png';
import Noah from './noah.png';

const getAvatar = avatar => {
  switch (avatar) {
    default:
    case 'avatarBlue':
      return AvatarBlue;
    case 'billy':
      return BillyB;
    case 'taylor':
      return TaylorS;
    case 'jackie':
      return JackieF;
    case 'rachel':
      return RachelM;
    case 'timothy':
      return TimothyR;
    case 'michael':
      return MichaelM;
    case 'jackieL':
      return JackieL;
    case 'noah':
      return Noah;
  }
};

function TestimonialCard(props) {
    const { rows } = props
  
    return (
        <CustomerCard>
          <AvatarImg src={getAvatar(props.avatar)} />
          <TextWrapper>
            <img src={FiveStars} />
            <PageSmall color="trulyDark"> {props.quote}</PageSmall>
            <PageLabel pt="10px">{props.name}<br/>{props.company}</PageLabel>
          </TextWrapper>
        </CustomerCard>
    )
}

export default TestimonialCard

TestimonialCard.defaultProps = {
  quote: 'Truly Is Great!',
  name: 'Jane Doe',
  company: 'Truly User',
};

const AvatarImg = styled.img`
  width: 60pt;
  height: 60pt;
  border-radius: 60pt;
  border: ${colors.trulyBlue} 4px solid;
  margin: 8pt;
  margin-right: 8pt;
`

const Icon = styled.img`
  width: 60pt;
  height: 60pt;
  padding: 8pt;
  margin-right: 8pt;
`
const TextWrapper = styled.div`
  width: 100%;
`

const CustomerCard = styled.div`
  background-color: white;
  width: 32%;
  border-radius: 8pt;
  margin: 8px;
  height: auto;
  flex-grow: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 16pt 8pt;
  text-align: left;
  justify-content: left;
  align-items: top;
  color: black;
  @media (max-width: 1000px) {
    width: 100%;
  }
`